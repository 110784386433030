var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.ipAddresses
    ? _c("div", { staticClass: "glances-ip-addr-wrapper" }, [
        _vm.ipAddresses.public_address
          ? _c("div", { staticClass: "ip-row public-ip" }, [
              _c("span", { staticClass: "lbl" }, [_vm._v("Public IP")]),
              _c("span", { staticClass: "val" }, [
                _vm._v(_vm._s(_vm.ipAddresses.public_address)),
              ]),
            ])
          : _vm._e(),
        _vm.ipAddresses.address
          ? _c("div", { staticClass: "ip-row" }, [
              _c("span", { staticClass: "lbl" }, [_vm._v("Local Address")]),
              _c("span", { staticClass: "val" }, [
                _vm._v(_vm._s(_vm.ipAddresses.address)),
              ]),
            ])
          : _vm._e(),
        _vm.ipAddresses.gateway
          ? _c("div", { staticClass: "ip-row" }, [
              _c("span", { staticClass: "lbl" }, [_vm._v("Gateway")]),
              _c("span", { staticClass: "val" }, [
                _vm._v(_vm._s(_vm.ipAddresses.gateway)),
              ]),
            ])
          : _vm._e(),
        _vm.ipAddresses.mask
          ? _c("div", { staticClass: "ip-row" }, [
              _c("span", { staticClass: "lbl" }, [_vm._v("Mask")]),
              _c("span", { staticClass: "val" }, [
                _vm._v(_vm._s(_vm.ipAddresses.mask)),
              ]),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }